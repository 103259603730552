import makeDebug from 'debug'
const debug = makeDebug('plp:banners')
import qs from 'qs'
import isEmpty from 'lodash/isEmpty'

var banners = null
let parser
let docBanners

// TO unCOMMENT WHEN IS USED MOCK RESPONSE
// export const bannerMockResponse =
//   '<script type="html/text" data-plp-banner data-banner-position="12" data-banner-size="1"><div class="sgh-col w-full sm:w-1/3"><!-- BEGIN com.coremedia.blueprint.cae.contentbeans.CMTeaserImpl$$[id=140000] asTextModule[s&#8211;bmtext&#8211;image&#8211;m] FreemarkerView[template=/jar:id:contentproperty:/Themes/sghwcs/templates/sghwcs&#8211;templates.jar/archive!/META&#8211;INF/resources/WEB&#8211;INF/templates/sghwcs/com.coremedia.blueprint.common.contentbeans/CMTeaser.asTextModule.ftl] --><div class="px-10 mb-24 sm:px-40 sm:mb-0"><div class="font-serif text-base+ sm:text-4xl leading-tight mb-10 text-emperor"><p></p></div> <h3 class="font-sans font-thin text-15xl sm:text-20xl leading-none mb-8 text-emperor"></h3> <div class="font-serif text-base+ sm:text-4xl leading-tight mb-10 text-emperor"> <p> <p>DOMENICO this is some random text </p> <p>I do it for testing.Whoa, look at that, have you ever seen such a text? this Has to be the textiest text ever been written in the history of all text ever!</p> </p> </div> </div> <!-- END com.coremedia.blueprint.cae.contentbeans.CMTeaserImpl$$[id=140000] asTextModule[s&#8211;bmtext&#8211;image&#8211;m] FreemarkerView[template=/jar:id:contentproperty:/Themes/sghwcs/templates/sghwcs&#8211;templates.jar/archive!/META&#8211;INF/resources/WEB&#8211;INF/templates/sghwcs/com.coremedia.blueprint.common.contentbeans/CMTeaser.asTextModule.ftl] took 0 ms --></div></script>'

const getBanners = cmsFilteredBanners => {
  const currentParams = qs.parse(decodeURIComponent(window.location.search), {
    ignoreQueryPrefix: true,
  })
  if (cmsFilteredBanners) {
    parser = new DOMParser()
    docBanners = parser.parseFromString(cmsFilteredBanners, 'text/html')
  } else {
    docBanners = document
  }
  //if there are filtered banners and filters are applied don't show default banners
  let inlineBanner = []
  if (isEmpty(currentParams.facet)) {
    inlineBanner = [...docBanners.querySelectorAll('[data-plp-banner]')]
  } else if (cmsFilteredBanners) {
    inlineBanner = [...docBanners.querySelectorAll('[data-plp-banner-dynamic]')]
  }

  let lastSize = 0
  banners = []
  inlineBanner.reduce((acc, node) => {
    const position = Number(node.dataset.bannerPosition)
    const size = Number(node.dataset.bannerSize)

    const element = new DOMParser().parseFromString(node.innerText, 'text/html')
    if (element) {
      if (element.body.children.length > 1) {
        element.body.children.forEach((child, index) => {
          banners.push({
            position: position + index,
            size,
            template: child.outerHTML,
          })
        })
      } else {
        banners.push({
          position,
          size,
          template: node.innerText,
        })
      }
    }
  }, [])
  banners = banners.sort((a, b) => {
    if (a.position < b.position) {
      return -1
    }
    if (a.position > b.position) {
      return 1
    }
    return 0
  })

  banners = banners.reduce((banners, banner) => {
    let bannerSlots = banners[banner.position - lastSize] || []
    bannerSlots.push(banner.template)
    banners[banner.position - lastSize] = bannerSlots
    lastSize += banner.size

    return banners
  }, {})
  debug('banners cluster %o', banners)
  return banners
}
export const getBannerSlots = (position, cmsFilteredBanners) => {
  const b = getBanners(cmsFilteredBanners)
  return b[position] ? b[position] : ''
}

export default {
  name: 'sgh-dynamic-plp-banner',
  props: {
    position: Number,
    bannerTemplate: String,
  },
  render(h) {
    debug('place banner at position %i', this.position)
    // const template = getBannerSlots(this.position)
    const position = this.position
    const dynComponent = {
      name: 'PlpBanner' + position,
      template: this.bannerTemplate,
    }
    const component = this.bannerTemplate ? dynComponent : null
    return h(component)
  },
}
