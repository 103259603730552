
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import { createNamespacedHelpers } from 'vuex'
const { mapActions, mapGetters } = createNamespacedHelpers('plp')

import {
  ACTION_UPDATE_IDS_FILTERS_CHECKED,
  ACTION_TOGGLE_FILTER_ITEM,
  ACTION_REMOVE_FACET_COUNT,
  ACTION_UPDATE_FILTER_CATEGORY_LABEL,
  ACTION_CLONE_FILTER_OPTIONS_LIST,
} from '../../../scripts/store/actions/plp'
import { getCheckedList } from '../../../scripts/libs/utils/checkedList'
import { getSAHingeFilterId } from '../../libs/utils/const'

export default {
  name: 'sgh-filter-item',
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
    open: {
      type: Boolean,
      default: false,
    },
    isSon: {
      type: Boolean,
      default: false,
    },
    accordion: {
      type: HTMLDivElement,
      default: () => {},
    },
  },
  data: function() {
    return {
      toggleSon: this.$props.open,
      checkedElement: [],
      isSizeSwitchToggled: false,
      translate: 0,
      suggestedHinges: undefined,
      hingeFilterIdSA: '',
      position: 0,
      scrollHeight: '3.7rem',
      firstOpen: true,
      useAlgolia: window.algoliaConfig?.isActive || false,
    }
  },
  computed: {
    ...mapGetters([
      'facets',
      'idsFiltersCheckedList',
      'extraFacets',
      'ui',
      'facetsGroupNames',
      'facetsCount',
      'isFilterCategoryLabel',
      'isFilterCategoryHidden',
    ]),

    setScrollHeight() {
      let style = ''
      style = !this.firstOpen
        ? { '--scroll-height': this.scrollHeight + 'px' }
        : { '--scroll-height': '3.7rem' }
      return style
    },
    toggle() {
      return this.label === this.ui.filterItemClicked || this.toggleSon
    },
    list() {
      return this.filter.list
    },
    label() {
      return this.filter.label
    },
    others() {
      return this.filter.others
    },
    groupName() {
      return this.filter.groupName
    },
    dash() {
      return this.filter.groupName === 'dashCheckBoxes'
    },
    hideFilters() {
      return this.sizeAdvisorFilterApplied
    },
    isSizeFilter() {
      return ['fit', 'attributes_translated.FRAME_SIZE_DISPLAY'].includes(this.groupName)
    },
    sizeAdvisorFilterApplied() {
      return find(this.extraFacets, facet => {
        return facet.includes(this.hingeFilterIdSA)
      }) ||
        find(this.idsFiltersCheckedList, facet => {
          return this.useAlgolia ? facet.includes('Hinge') : facet.includes(this.hingeFilterIdSA)
        })
        ? true
        : false
    },
    sizeAdvisorExperienceDone() {
      if (!isEmpty(sessionStorage.getItem('sizeAdvisorSuggestedHinges'))) {
        return true
      }
      return false
    },
    frameAdvisorExperienceDone() {
      if (!isEmpty(sessionStorage.getItem('frameAdvisorSuggestedHinges'))) {
        return true
      }
      return false
    },
  },
  watch: {
    idsFiltersCheckedList() {
      if (this.idsFiltersCheckedList && this.idsFiltersCheckedList.length === 0) {
        this.checkedElement = getCheckedList(this.$props.filter)
      }
    },
  },
  mounted() {
    this.hingeFilterIdSA = getSAHingeFilterId()
    if (this.$props.filter) {
      this.checkedElement = getCheckedList(this.$props.filter)
    }
    if (this.isFilterCategoryLabel) {
      this.cloneFilterOptionsList(this.facets.filterOptionsList)
      this.updateFilterCategoryLabel(false)
    }
    if (this.$refs.filterAction) {
      this.scrollHeight = this.$refs.accordion.scrollHeight
    }
  },
  methods: {
    ...mapActions({
      updateIdsFiltersCheckedList: ACTION_UPDATE_IDS_FILTERS_CHECKED,
      toggleFilterItem: ACTION_TOGGLE_FILTER_ITEM,
      removeFacetCount: ACTION_REMOVE_FACET_COUNT,
      updateFilterCategoryLabel: ACTION_UPDATE_FILTER_CATEGORY_LABEL,
      cloneFilterOptionsList: ACTION_CLONE_FILTER_OPTIONS_LIST,
    }),
    slideRight() {
      const box = this.$refs.custom_slider
      let distance = 100
      let limit = box.scrollWidth - box.clientWidth
      if (box.scrollLeft + distance >= limit) {
        box.scrollLeft = limit
        document.getElementById('next').classList.remove('visible-arrow')
        document.getElementById('mobile-next').classList.remove('gradient-right')
      } else {
        document.getElementById('mobile-prev').classList.add('gradient-left')
        box.scrollLeft += distance
      }
      document.getElementById('prev').classList.add('visible-arrow')
    },
    slideLeft() {
      const box = this.$refs.custom_slider
      let distance = 100
      if (box.scrollLeft - distance <= 0) {
        box.scrollLeft = 0
        document.getElementById('prev').classList.remove('visible-arrow')
        document.getElementById('mobile-prev').classList.remove('gradient-left')
      } else {
        document.getElementById('mobile-next').classList.add('gradient-right')
        box.scrollLeft -= distance
      }
      document.getElementById('next').classList.add('visible-arrow')
    },
    mobileSlide() {
      const box = this.$refs.custom_slider
      let limit = box.scrollWidth - box.clientWidth - 1
      if (box.scrollLeft <= 0) {
        document.getElementById('mobile-prev').classList.remove('gradient-left')
        document.getElementById('mobile-next').classList.add('gradient-right')
      } else if (box.scrollLeft >= limit) {
        document.getElementById('mobile-next').classList.remove('gradient-right')
        document.getElementById('mobile-prev').classList.add('gradient-left')
      } else {
        document.getElementById('mobile-prev').classList.add('gradient-left')
        document.getElementById('mobile-next').classList.add('gradient-right')
      }
    },
    sizeAdvSwitchClicked(force) {
      this.suggestedHinges = sessionStorage.getItem('sizeAdvisorSuggestedHinges')
        ? JSON.parse(sessionStorage.getItem('sizeAdvisorSuggestedHinges'))
        : sessionStorage.getItem('frameAdvisorSuggestedHinges')
        ? JSON.parse(sessionStorage.getItem('frameAdvisorSuggestedHinges'))
        : undefined

      if (this.useAlgolia && !isEmpty(this.suggestedHinges))
        this.updateIdsFiltersCheckedList({
          id: `Hinge:{${this.suggestedHinges.minHinge}, ${this.suggestedHinges.maxHinge}}`,
        })
      else if (!isEmpty(this.suggestedHinges) && this.hingeFilterIdSA != undefined) {
        let hingeRangeFacetId = `${this.hingeFilterIdSA}:({${this.suggestedHinges.minHinge} ${this.suggestedHinges.maxHinge}} ${this.suggestedHinges.maxHinge})`
        if (!force || !this.isChecked(hingeRangeFacetId)) this.checked(hingeRangeFacetId)
      } else {
        console.error(
          'No Hinges suggestions from Size / Frame Advisor App found in session storage'
        )
      }
    },
    iconClass(icon, iconSize) {
      if (this.dash && icon === 'pilot') icon = 'pilot-shape'
      let computedClass = `common__icon--filters--${icon}`
      return iconSize ? `common__icon--${iconSize} ${computedClass}` : computedClass
    },
    showFilterCategoryLabel() {
      if (!this.filter.list) return
      if (this.filter.groupName === 'dashCheckBoxes') return true
      if (this.filter.groupName === 'hingeDistance') return false
      if (this.isFilterCategoryLabel) {
        let listArray = this.filter.list.filter(elem => String(elem.count) !== '0')
        //RX SGHDP-14983: for "prescription" allow even if just 1 option
        this.filter.isHidden =
          listArray.length > 1 ||
          (['prescription'].includes(this.filter.groupName) && listArray.length >= 1)
        return this.filter.isHidden
      }
      const [{ isHidden }] = this.isFilterCategoryHidden.filter(
        elem => elem.groupName === this.filter.groupName
      )
      return isHidden
    },
    checked(id) {
      this.updateIdsFiltersCheckedList({ id, groupName: this.groupName })
      // TODO: update test on click and also the number of filters applied
    },
    isChecked(id) {
      return this.idsFiltersCheckedList.find(e => {
        return e === id
      })
    },
    disabled({ count }) {
      return parseInt(count) === 0
    },
    hidden(id, count) {
      if (count == 0 && this.facetsCount[id] != undefined) {
        // if the current filters count is == 0 and
        // in the object with all the filters count at first load
        // the count is=0 then we hide the filter
        return parseInt(this.facetsCount[id]) === 0
      } else if (count > 0 && parseInt(this.facetsCount[id]) === 0) {
        // if the count has become bigger than zero but on
        // the first load count it was 0 than we show the filter again
        this.removeFacetCount(id)
        return false
      }
      return false
    },
    // end SGHDP-10243
    getCheckedElementLength() {
      if (this.facetsGroupNames[this.groupName]) {
        return this.facetsGroupNames[this.groupName].length
      }
      return 0
    },
    setScroll() {
      this.$root.$emit('scrollPositionSidebar', this.$refs.accordion)
    },
    clickFilterItem(event, toggle) {
      if (!this.isSon) {
        if (this.label === this.ui.filterItemClicked) {
          this.toggleFilterItem('')
        } else {
          this.toggleFilterItem(this.label)
          this.firstOpen = false
          if (this.$refs.accordion) {
            this.setScroll()
          }
        }
      } else {
        this.toggleSon = !this.toggleSon
      }
    },
  },
}
