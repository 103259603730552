
import { loadSectionTranslations } from '../../i18n-setup'

export default {
  name: 'sgh-filter-size-advisor',
  props: {
    toggled: {
      type: Boolean,
      default: false,
    },
    isFasaExperienceDone: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    loadSectionTranslations('framegenius')
    // Listen for the SA Experience completed event -> preselect SA filter
    document.addEventListener('SA-done', () => {
      this.$emit('sizeAdvSwitchClicked', true)
    })
    //if first time loading plp and SA experience done -> preselect SA filter
    if (this.isFasaExperienceDone) {
      if (!sessionStorage.getItem('dontPreselectSAFilter')) {
        sessionStorage.setItem('dontPreselectSAFilter', true)
        this.$emit('sizeAdvSwitchClicked')
      }
    }
  },
}
