
export default {
  name: 'sgh-scroll-categories',
  data: function() {
    return {
      defaultConfig: {
        slidesPerView: 'auto',
        spaceBetween: 12,
      },
    }
  },
}
