
export default {
  name: 'sgh-read-more',

  props: {
    desktopvisible: {
      type: Boolean,
      default() {
        return false
      },
    },
    mobilevisible: {
      type: Boolean,
      default() {
        return true
      },
    },
    heightCut: {
      type: Number,
      default() {
        return 100
      },
    },
  },

  data() {
    return {}
  },

  mounted() {
    this.hideReadMore()
    window.addEventListener('resize', this.hideReadMore)
  },

  methods: {
    click: function() {
      this.$el.previousElementSibling.classList.toggle('full-visible')
      this.$el.firstElementChild.classList.toggle('hidden')
      this.$el.lastElementChild.classList.toggle('hidden')
    },
    hideReadMore: function() {
      this.$el.previousElementSibling.scrollHeight - 4 > this.heightCut
        ? this.$el.classList.remove('hidden')
        : this.$el.classList.add('hidden')
    },
  },
}
