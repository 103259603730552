
import { createNamespacedHelpers } from 'vuex'
import { ACTION_LOAD_MORE, ACTION_LOAD_PLP } from '../../../scripts/store/actions/plp'
import sghDynamicPlpBanner, { getBannerSlots } from '../dynamic-plp-banner'
import checkIsVirtualMirrorSupported from '../../helpers/checkIsVirtualMirrorSupported'
import createProductsDataAnalytics from '../../helpers/createProductsDataAnalytics'
import { loadSectionTranslations } from '../../i18n-setup'
import FaqsImplementation from '../ui-components/faqs-implementation.vue'

const { mapActions, mapGetters: mapGettersPlp } = createNamespacedHelpers('plp')

export default {
  name: 'sgh-plp-container',
  components: {
    sghDynamicPlpBanner,
    FaqsImplementation,
  },
  props: {
    redirectGuestFunction: {
      type: String,
      default: '',
    },
  },
  data: function() {
    return {
      userType:
        window.constants && window.constants.ajaxParams.userType.length != 0
          ? window.constants.ajaxParams.userType
          : 'G',
      isSizeAdvisorEnabled: sessionStorage.getItem('isSizeAdvisorEnabled') == 'true',
      isFirstLanding: true,
      showSkeletonProdcuctTile: true,
    }
  },
  computed: {
    ...mapGettersPlp([
      'facets',
      'attributes',
      'idsFiltersCheckedList',
      'filteredCMSBanners',
      'filterOptionsList',
      'pagination',
      'products',
      'ui',
    ]),

    showProducts() {
      const productsExist = this.products && this.products.length > 0

      if (productsExist && this.isFirstLanding) {
        this.triggerAnalytics()
      }

      return productsExist
    },
    showLoading() {
      // SGHDDP-8816 (mask products shows during jsp)
      return this.ui.plpLoadingProducts || !this.showProducts
    },
    gridSlots() {
      return this.products.reduce((elements, product, index) => {
        let filteredCMSBannersPlp = ''
        filteredCMSBannersPlp = this.filteredCMSBanners
        const bannerSlots = getBannerSlots(index + 1, filteredCMSBannersPlp) || []
        bannerSlots.forEach(banner => elements.push({ type: 'banner', template: banner }))
        elements.push({ type: 'products', product })
        return elements
      }, [])
    },
    getGridSlotsLength() {
      return this.gridSlots.reduce((acc, elem) => acc + (elem.type === 'banner' ? 1 : 0), 0)
    },
    //define a computed getter and setter to handle default and updated widthCol
    widthCol: {
      get: function() {
        return this.ui && this.ui.mobileView === 'GRID_4' ? 'w-1/2' : 'w-full'
      },
      set: function(newValue) {
        return this.ui && (this.ui.mobileView = newValue === 'w-full' ? 'GRID_2' : 'GRID_4')
      },
    },
  },
  mounted() {
    this.$root.$once('plpProductsLoaded', () => {
      this.showSkeletonProdcuctTile = false
      //hide dynamic banner
      const banner = document.querySelectorAll('.skeleton .dynamic-banner:not(.hidden)')
      if (banner.length) {
        banner.forEach(element => element.classList.add('hidden'))
      }
    })
  },
  async created() {
    await loadSectionTranslations('plp')
    this.loadPlp()
  },
  methods: {
    ...mapActions({
      actionLoadMore: ACTION_LOAD_MORE,
      loadPlp: ACTION_LOAD_PLP,
    }),
    loadMore() {
      this.actionLoadMore({
        currentPage: this.pagination.currentPage + 1,
        updateUi: {
          plpLoadingMoreProducts: true,
        },
      })
    },
    triggerAnalytics() {
      const isVirtualMirrorSupported = checkIsVirtualMirrorSupported()

      if (window.utag_data) {
        window.utag_data.Products = createProductsDataAnalytics(
          this.products,
          isVirtualMirrorSupported
        )

        window.utag_data.Vm_IsBrowserSupported = isVirtualMirrorSupported

        const obj = {
          id: 'Loading-Ready',
          Products: window.utag_data.Products,
        }

        window.tealium_data2track.push(obj)
      }
      this.isFirstLanding = false
    },
  },
}
