
import { createNamespacedHelpers } from 'vuex'
import { loadSectionTranslations } from '../../../i18n-setup'
import { ACTION_LOAD_MORE, ACTION_LOAD_PLP } from '../../../../scripts/store/actions/plp'
import checkIsVirtualMirrorSupported from '../../../helpers/checkIsVirtualMirrorSupported'
import createProductsDataAnalytics from '../../../helpers/createProductsDataAnalytics'

const { mapGetters: mapGettersPlp, mapActions } = createNamespacedHelpers('plp')

export default {
  name: 'sgh-plp-container-chanel',
  props: {
    redirectGuestFunction: {
      type: String,
      default: '',
    },
  },
  data: function() {
    return {
      userType:
        window.constants && window.constants.ajaxParams.userType.length != 0
          ? window.constants.ajaxParams.userType
          : 'G',
    }
  },
  computed: {
    ...mapGettersPlp(['products', 'pagination', 'ui']),
    showProducts() {
      const productsExist = this.products?.length > 0

      if (productsExist) {
        this.triggerAnalytics()
      }

      return productsExist
    },
    showLoading() {
      // SGHDDP-8816 (mask products shows during jsp)
      return this.ui.plpLoadingProducts || !this.showProducts
    },
  },
  async created() {
    await loadSectionTranslations('plp')
    this.loadPlp()
  },
  methods: {
    ...mapActions({
      actionLoadMore: ACTION_LOAD_MORE,
      loadPlp: ACTION_LOAD_PLP,
    }),
    loadMore() {
      this.actionLoadMore({
        currentPage: this.pagination.currentPage + 1,
        updateUi: {
          plpLoadingMoreProducts: true,
        },
      })
    },
    triggerAnalytics() {
      const isVirtualMirrorSupported = checkIsVirtualMirrorSupported()

      if (window.utag_data) {
        window.utag_data.Products = createProductsDataAnalytics(
          this.products,
          isVirtualMirrorSupported
        )
        window.utag_data.Vm_IsBrowserSupported = isVirtualMirrorSupported
        const obj = {
          id: 'Loading-Ready',
          Products: window.utag_data.Products,
        }

        window.tealium_data2track.push(obj)
      }
    },
  },
}
