
import { createNamespacedHelpers } from 'vuex'
import { ACTION_TOGGLE_PLP_LOADING_MORE_PRODUCT } from '../../../../scripts/store/actions/plp'
import { loadSectionTranslations } from '../../../i18n-setup'
const { mapActions } = createNamespacedHelpers('plp')

export default {
  name: 'sgh-load-more-chanel',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 0,
    },
    totalPages: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    showButton() {
      return !this.isLoading ? this.currentPage !== this.totalPages : false
    },
  },
  mounted() {
    loadSectionTranslations('plp')
  },
  methods: {
    ...mapActions({
      toggle: ACTION_TOGGLE_PLP_LOADING_MORE_PRODUCT,
    }),
    click: function() {
      this.toggle()
      this.$emit('loadMore')
    },
  },
}
