import Vue from 'vue'

/* All components to be declared */
/* Declare in this way components that will be put in chunks and loaded not immediately */
Vue.component('sgh-seo-text-plp', () =>
  import(
    /* webpackChunkName: "cms-modules" */
    './components/cms-modules/seo-text/seo-text-plp.vue'
  )
)

Vue.component('sgh-sidebar', () =>
  import(
    /* webpackChunkName: "sidebar" */
    './components/sidebar/sidebar.vue'
  )
)

/* Declare in this way component that needs to appear immediately (will be put in main.js) */
import sghFiltersActionsChanel from './components/chanel/filters/filter-actions.vue'
Vue.component('sgh-filters-actions-chanel', sghFiltersActionsChanel)

import sghLoadMoreChanel from './components/chanel/load-more/load-more.vue'
Vue.component('sgh-load-more-chanel', sghLoadMoreChanel)

import sghPlpContainerChanel from './components/chanel/plp/plp-container.vue'
Vue.component('sgh-plp-container-chanel', sghPlpContainerChanel)

import sghPlpTileChanel from './components/chanel/tile/plp-tile.vue'
Vue.component('sgh-plp-tile-chanel', sghPlpTileChanel)

import sghFiltersActions from './components/filters/filter-actions.vue'
Vue.component('sgh-filters-actions', sghFiltersActions)

import sghFilterBy from './components/filters/filter-by.vue'
Vue.component('sgh-filter-by', sghFilterBy)

import sghFilterItem from './components/filters/filter-item.vue'
Vue.component('sgh-filter-item', sghFilterItem)

import sghFilterSizeAdvisor from './components/filters/filter-size-advisor.vue'
Vue.component('sgh-filter-size-advisor', sghFilterSizeAdvisor)

import sghCategory from './components/header/category.vue'
Vue.component('sgh-category', sghCategory)

import sghHeader from './components/header/header.vue'
Vue.component('sgh-header', sghHeader)

import sghHeaderContentSkeleton from './components/header/header-content-skeleton.vue'
Vue.component('sgh-header-content-skeleton', sghHeaderContentSkeleton)

import sghScrollCategories from './components/header/scroll-categories.vue'
Vue.component('sgh-scroll-categories', sghScrollCategories)

import plpPlaceholder from './components/placeholder/plp-placeholder.vue'
Vue.component('plp-placeholder', plpPlaceholder)

import sghPlpContainer from './components/plp/plp-container.vue'
Vue.component('sgh-plp-container', sghPlpContainer)

import sghReadMore from './components/read-more/read-more.vue'
Vue.component('sgh-read-more', sghReadMore)
