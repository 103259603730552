
import { sameUrl, checkUrlParameter } from '../../libs/utils/string'
import { loadSectionTranslations } from '../../i18n-setup'
export default {
  name: 'sgh-category',
  props: {
    img: {
      type: String,
      default: undefined,
    },
    link: {
      type: String,
      default: '#',
    },
    categoryName: {
      type: String,
      default: '',
    },
    isSale: {
      type: Boolean,
      default: false,
    },
    dataElementId: {
      type: String,
      default: '',
    },
  },
  computed: {
    selected() {
      if (
        sameUrl(window.location.href, this.link) ||
        checkUrlParameter('cmsPageId', window.location.href, this.link)
      ) {
        return true
      }
      return false
    },
  },
  mounted() {
    loadSectionTranslations('plp')
  },
  methods: {
    goToCategory() {
      return this.$t('plp_go_to') + ' ' + this.categoryName
    },
  },
}
