
import { createNamespacedHelpers } from 'vuex'
import {
  ACTION_TOGGLE_PLP_FILTER,
  ACTION_UPDATE_IDS_FILTERS_CHECKED,
  ACTION_UPDATE_FACETS,
  ACTION_LOAD_PLP_FACETS,
  ACTION_TOGGLE_FILTER_ITEM,
} from '../../../scripts/store/actions/plp'
import { loadSectionTranslations } from '../../i18n-setup'
import { prepareFilterTrackingString } from '../../libs/wcs/plp'

const { mapActions, mapGetters: mapGettersPlp } = createNamespacedHelpers('plp')

export default {
  name: 'sgh-filter-by',
  data: function() {
    return {
      buttonClicked: false,
      clearAllClicked: false,
    }
  },
  computed: {
    ...mapGettersPlp([
      'facets',
      'facetsSelected',
      'idsFiltersCheckedList',
      'ui',
      'filterOptionsList',
      'sortOptionList',
    ]),
    isMobile() {
      return this.$mq == 'tablet' || this.$mq == 'tabletLandscape'
    },
  },
  mounted() {
    this.buttonClicked = false
    this.clearAllClicked = false
    loadSectionTranslations('plp')
  },
  methods: {
    ...mapActions({
      toggleFilter: ACTION_TOGGLE_PLP_FILTER,
      updateIdsFiltersCheckedList: ACTION_UPDATE_IDS_FILTERS_CHECKED,
      updateFacets: ACTION_UPDATE_FACETS,
      loadPlpFacets: ACTION_LOAD_PLP_FACETS,
      toggleFilterItem: ACTION_TOGGLE_FILTER_ITEM,
    }),
    clearAll() {
      this.clearAllClicked = true
      this.updateIdsFiltersCheckedList({ reset: true })
      this.updateFacets({
        currentPage: 1,
        override: true,
        updateUi: {
          plpLoadingProducts: true,
        },
        clearUrl: true,
      })
      this.toggleFilterItem('')
    },
    applyFilters() {
      this.buttonClicked = true
      this.updateFacets({
        currentPage: 1,
        override: true,
        updateUi: {
          plpLoadingProducts: true,
        },
      })
      this.toggleFilter()
      window.scrollTo(0, 0)

      prepareFilterTrackingString(
        this.idsFiltersCheckedList,
        this.filterOptionsList,
        this.facets,
        ''
      ).then(trackingData => {
        this.$ma.trackEvent({
          event: 'SearchFilterUpdated',
          value: trackingData,
        })
      })
    },
    closePanel() {
      this.$root.$emit('panelStatusOn')

      if (!this.buttonClicked) {
        this.toggleFilterItem('')
      }
      this.toggleFilter()
      this.$root.$emit('panelStatusOff')
    },
    applySort(id) {
      // TODO: complete for the mobile, not clear what we should do
      // eslint-disable-next-line no-console
      console.log('id pressed: ', id)
    },
    getKey(filter, index) {
      const listLength = filter.list ? filter.list.length : 0
      let othersLength = 0
      if (filter.others) {
        othersLength = filter.others.find(a => a.groupName === 'allBrands')
          ? filter.others.find(a => a.groupName === 'allBrands').list.length
          : 0
      }
      return `${index}${listLength}${othersLength}`
    },
  },
}
