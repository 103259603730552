
export default {
  name: 'plp-placeholder',
  props: {
    mobileView: {
      type: String,
      default: 'w-full',
    },
    productLoad: {
      type: Boolean,
      default: true,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    gridSlotsPlaceholder() {
      return new Array(18 * this.page)
    },
  },
  mounted() {
    this.disableDisplayPlpTileSkeletonServer()
  },
  methods: {
    disableDisplayPlpTileSkeletonServer() {
      const plpTileSkeleton = document.querySelector('.skeleton .plp-tile-container')
      //hide plp tile skeleton
      if (plpTileSkeleton) {
        plpTileSkeleton.classList.add('hidden')
      }
    },
  },
}
